var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wall",
      class: [_vm.type ? "wall--" + _vm.type : ""],
      style: {
        left: _vm.left + "vw",
        top: _vm.top + "vh",
        marginTop: -(_vm.modeHeight / 2) + "vh",
        width: _vm.width + _vm.widthUnit,
        height: _vm.modeHeight + _vm.heightUnit,
        zIndex: _vm.zIndex,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "faces",
          style: {
            perspectiveOrigin:
              _vm.xPerspective + "vw " + _vm.calculatedYPerspective + "vh",
          },
        },
        [
          _vm.sides.includes("front")
            ? _c("div", { staticClass: "face face--front" })
            : _vm._e(),
          _vm._v(" "),
          _vm.sides.includes("back")
            ? _c("div", { staticClass: "face face--back" })
            : _vm._e(),
          _vm._v(" "),
          _vm.sides.includes("right")
            ? _c("div", {
                staticClass: "face face--right",
                style: {
                  transform:
                    "rotateY(90deg) translateZ(calc(" +
                    _vm.width +
                    "vw - " +
                    _vm.zHeight / 2 +
                    "px))",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.sides.includes("left")
            ? _c("div", { staticClass: "face face--left" })
            : _vm._e(),
          _vm._v(" "),
          _vm.sides.includes("top")
            ? _c("div", { staticClass: "face face--top" })
            : _vm._e(),
          _vm._v(" "),
          _vm.sides.includes("bottom")
            ? _c("div", {
                staticClass: "face face--bottom",
                style: {
                  transform:
                    "rotateX(-90deg) translateZ(calc(" +
                    _vm.modeHeight +
                    _vm.heightUnit +
                    " - " +
                    _vm.zHeight / 2 +
                    "px)) translateY(" +
                    (50 - _vm.zHeight / 2) +
                    "px)",
                },
              })
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }