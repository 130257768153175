<template>
  <div id="app">
    <div class="viewport" id="viewport">
      <div class="texture"></div>
      <transition name="fade">
        <div class="scroll-container" id="scroll-container" v-show="!isLoading">
          <transition name="scroll-in">
            <div class="floor" :style="{ left: (scrollerX/ 2.0) +'px', width:scrollWidth + 'vw' }" v-show="!isLoading">
              <a class="trailer-button" @click.prevent="onTrailerClick">
                <span class="small splittable">Watch</span>
                <span class="splittable">Trailer</span>
              </a>

              <a class="buy-now-button splittable" @click.prevent="onBuyClick">Buy now</a>

              <span class="logo">APE OUT</span>

              <Object v-for="(object, index) in objects" :key="index" 
                :type=object.type
                :top=object.top
                :left=object.left*xMultiplier+xAdjuster
                v-show="(!object.appears_after && !object.disappears_after) || (object.disappears_after && object.disappears_after > furthestScrolledInVW) || (object.appears_after && object.appears_after < furthestScrolledInVW)" />

              <div class="buy-now" :style="{ left: 494*xMultiplier+xAdjuster + 'vw' }">
                <div class="buy-now__text"></div>
                <div class="buy-now__list">
                  <a class="buy-now__icon buy-now__icon--switch" target="_blank" href="https://www.nintendo.com/games/detail/ape-out-switch" @click="onStoreClick('Nintendo')"></a>
                  <a class="buy-now__icon buy-now__icon--steam" target="_blank" href="https://store.steampowered.com/app/447150/Ape_Out/" @click="onStoreClick('Steam')"></a>
                  <a class="buy-now__icon buy-now__icon--gog" target="_blank" href="https://www.gog.com/game/ape_out?utm_medium=link&utm_source=Devolver&utm_campaign=partner_links" @click="onStoreClick('GOG')"></a>
                  <a class="buy-now__icon buy-now__icon--humble" target="_blank" href="https://www.humblebundle.com/store/ape-out" @click="onStoreClick('Humble Bundle')"></a>
                  <a class="buy-now__icon buy-now__icon--itch" target="_blank" href="https://devolverdigital.itch.io/ape-out" @click="onStoreClick('Itch.io')"></a>
                </div>
              </div>
            </div>
          </transition>

          <transition name="scroll-in">
            <div class="walls" :style="{ width:scrollWidth + 'vw' }" v-show="!isLoading">
              <Wall v-for="(wall, index) in walls" :key="index"
                :type=wall.type 
                :top=wall.top
                :left=wall.left*xMultiplier+xAdjuster
                :width=wall.width*xMultiplier
                :height=wall.height
                :zHeight=wall.z_height 
                :portraitHeight=wall.portrait_height
                :maintainRatio=wall.maintain_ratio
                :displayMode=displayMode
                :yPerspective=wall.y_perspective
                :sides=wall.sides
                :vanishingPoint=vanishingPoint
                v-if="scrollInVW + 200 > wall.left*xMultiplier+xAdjuster && scrollInVW - 100 < wall.left*xMultiplier+xAdjuster + wall.width" />
            </div>
          </transition>
        </div>
      </transition>
    </div>

    <Trailer :isVisible=isTrailerVisible @closeTrailer="onTrailerClose" v-show="!isLoading" />
    
    <Credit name="cuzzillo"
      :scrollStart="280 * xMultiplier + xAdjuster"
      :scrollEnd="345 * xMultiplier + xAdjuster"
      :scrollLocation=scrollInVW v-show="!isLoading" />
    <Credit name="boch"
      :scrollStart="380 * xMultiplier + xAdjuster"
      :scrollEnd="445 * xMultiplier + xAdjuster"
      :scrollLocation=scrollInVW v-show="!isLoading" />
    <Credit name="foddy"
      :scrollStart="480 * xMultiplier + xAdjuster"
      :scrollEnd="545 * xMultiplier + xAdjuster"
      :scrollLocation=scrollInVW v-show="!isLoading" />
  </div>
</template>

<script>
  import { TweenLite } from 'gsap';
  import MobileDetect from 'mobile-detect';
  
  import Credit from './components/Credit.vue';
  import Object from './components/Object.vue';
  import Trailer from './components/Trailer.vue';
  import Wall from './components/Wall.vue';

  import objects from './assets/data/objects.json';
  import walls from './assets/data/walls.json';

  export default {
    name: 'app',
    components: {
      Credit,
      Object,
      Trailer,
      Wall
    },

    data: function() {
      return {
        requestId: 0,

        scrollerTarget: null,
        scrollerEase: 0.15,
        scrollerX: 0,
        scrollerResizeRequest: 1,
        scrollerScrollRequest: 0,

        objects:objects,
        walls:walls,

        scrollLocation:0,
        viewportWidth:0,
        xMultiplier:1,
        xAdjuster:0,
        displayMode: 'desktop',
        furthestScrolled:0,

        isLoading:true,
        isDevice: false,
        isTrailerVisible:false
      };
    },

    computed: {
      vanishingPoint: function() {
        return ((this.scrollerX + (this.viewportWidth / 2)) / this.viewportWidth) * 100;
      },

      scrollInVW: function() {
        return this.scrollerX/this.viewportWidth * 100;
      },

      scrollWidth: function() {
        return 1200 * this.xMultiplier + this.xAdjuster;
      },

      furthestScrolledInVW: function() {
        return this.furthestScrolled/this.viewportWidth * 100;
      }
    },

    mounted: function() {
      this.mobileDetect = new MobileDetect(window.navigator.userAgent);

      this.isDevice = this.mobileDetect.phone() ||this.mobileDetect.tablet();

      if (this.isDevice) {
        document.body.classList.add('is-device');
        this.scrollerEase=0.3;
      }

      this.scrollerTarget = this.$el.querySelector("#scroll-container");

      if (!this.isDevice) {
        TweenLite.set(this.scrollerTarget, {
          force3D: true
        });
      }

      this.splitText();

      window.addEventListener("load", this.onLoad);
    },

    methods: {
      onLoad: function() {
        window.focus();
        window.addEventListener("resize", this.onResize);

        if (this.isDevice) {
          document.getElementById('viewport').addEventListener("scroll", this.onScroll, false);
        } else {
          document.addEventListener("scroll", this.onScroll);
        }

        this.isLoading = false;
        this.setMode();
        this.onResize();
      },

      onScroll: function() {
        if (this.scrollerScrollRequest > 5) {
          return;
        }

        this.scrollerScrollRequest++;
        if (!this.requestId) {
          this.requestId = requestAnimationFrame(this.updateScroller);
        }
      },

      onResize: function() {
        if (this.scrollerResizeRequest > 5) {
          return;
        }

        this.scrollerResizeRequest++;
        if (!this.requestId) {
          this.requestId = requestAnimationFrame(this.updateScroller);
        }
      },

      updateScroller: function() {
        const resized = this.scrollerResizeRequest > 0;
        let scrollY;
        
        if (this.isDevice) {
          scrollY = document.getElementById('viewport').scrollLeft || 0;
        } else {
          scrollY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        }

        if (scrollY > this.furthestScrolled) {
          this.furthestScrolled = scrollY;
        }
          
        if (resized) {
          this.setMode();

          this.viewportWidth = window.innerWidth;
          
          if (!this.isDevice) {
            document.body.style.height = this.scrollerTarget.clientWidth - (window.innerWidth - window.innerHeight) + "px";
          }

          this.scrollerResizeRequest = 0;
        }

        this.scrollerX += (scrollY - this.scrollerX) * this.scrollerEase;

        if (Math.abs(scrollY - this.scrollerX) < this.scrollerEase || resized) {
          this.scrollerX = scrollY;
          this.scrollerScrollRequest = 0;
        }

        if (!this.isDevice) {
          TweenLite.set(this.scrollerTarget, { 
            x: -this.scrollerX 
          });
        }
        
        this.requestId = this.scrollerScrollRequest > 0 ? requestAnimationFrame(this.updateScroller) : null;
      },

      setMode: function() {
        if (window.innerWidth / window.innerHeight <= 1) {
          this.displayMode = 'portrait';
          this.xMultiplier = 1.2;
          this.xAdjuster = -10;
        }
        else if (window.innerWidth / window.innerHeight >=1.6) {
          this.displayMode = 'landscape';
          this.xMultiplier = 1;
          this.xAdjuster = 0;
        }
        else {
          this.displayMode = 'desktop';
          this.xMultiplier = 1;
          this.xAdjuster = 0;
        }
      },

      splitText: function() {
        document.querySelectorAll('.splittable').forEach((splittableText) => {
          splittableText.innerHTML = splittableText.innerHTML
            .split('')
            .map(letter => '<i>' + letter + '</i>')
            .join('');
        });
      },

      onBuyClick: function() {
        ga('send', 'event', 'Buy Shortcut', 'Click');
        this.scrollerScrollRequest = 50;

        if (this.isDevice) {
          document.getElementById('viewport').scrollTo(this.viewportWidth * 9.5 * this.xMultiplier, 0);
        }
        else {
          window.scrollTo(0, this.viewportWidth * 9.8 * this.xMultiplier);
        }

        this.updateScroller();
      },

      onTrailerClick: function() {
        ga('send', 'event', 'Trailer', 'Open');
        this.isTrailerVisible = true;
      },

      onTrailerClose: function() {
        this.isTrailerVisible = false;
      },

      onStoreClick: function(name) {
        ga('send', 'event', 'Store', 'Click', name);
      }
    }
  };
</script>
