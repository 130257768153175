var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "object",
    class: "object--" + _vm.type,
    style: { left: _vm.left + "vw", top: _vm.top + "vh" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }