<template>
  <div class="wall" :class="[ type ? 'wall--' + type :'' ]" :style="{ left:left+'vw', top:top +'vh', marginTop:-(modeHeight/2) +'vh', width:width+widthUnit, height:modeHeight+heightUnit, zIndex:zIndex }">
    <div class="faces" :style="{ perspectiveOrigin: xPerspective+'vw ' + calculatedYPerspective+'vh' }">
      <div class="face face--front" v-if="sides.includes('front')"></div>
      <div class="face face--back" v-if="sides.includes('back')"></div>
      <div class="face face--right" :style="{ transform: 'rotateY(90deg) translateZ(calc(' + width + 'vw - ' + zHeight/2 + 'px))'}" v-if="sides.includes('right')"></div>
      <div class="face face--left" v-if="sides.includes('left')"></div>
      <div class="face face--top" v-if="sides.includes('top')"></div>
      <div class="face face--bottom" :style="{ transform: 'rotateX(-90deg) translateZ(calc(' + modeHeight + heightUnit + ' - ' + zHeight/2 + 'px)) translateY(' + (50 - zHeight/2) +'px)' }" v-if="sides.includes('bottom')"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Wall',
    props: {
      name: String,
      left: Number,
      top: Number,
      width: Number,
      height: Number,
      portraitHeight: {
        type:Number,
        default:0
      },
      yPerspective: Number,
      type: String,
      vanishingPoint: Number,
      sides: Array,
      maintainRatio: {
        type:Boolean,
        default: false
      },
      zHeight: {
        type:Number,
        default: 100
      },
      displayMode: String
    },

    data: function() {
      return {
        calculatedYPerspective: Number
      }
    },

    computed: {
      xPerspective: function() {
        return (this.vanishingPoint - this.left);
      },

      zIndex: function() {
        if (this.xPerspective > 0) {
          return -parseInt(this.xPerspective) + 500;
        }

        return parseInt(this.xPerspective) + 500;
      },

      widthUnit: function() {
        if (this.maintainRatio && this.displayMode == 'portrait') {
          return 'vh';
        }

        return 'vw';
      },

      heightUnit: function() {
        if (this.maintainRatio && this.displayMode == 'landscape') {
          return 'vw';
        }

        return 'vh';
      },

      modeHeight: function() {
        if (this.displayMode == 'portrait' && this.portraitHeight) {
          return this.portraitHeight;
        }
        else {
          return this.height;
        }
      }
    },

    mounted: function() {
      this.calculatedYPerspective = 50 - this.top + (this.modeHeight/2);
    }
  }
</script>
