var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "credit is-shaking",
    class: ["credit--" + _vm.name, { "is-visible": _vm.isVisible }],
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }