var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "trailer-modal", class: { "is-visible": _vm.isVisible } },
    [
      _c("div", {
        staticClass: "trailer-modal__background",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.onCloseClick.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "trailer-modal__content" }, [
        _c("div", { staticClass: "trailer-modal__video" }, [
          _c(
            "a",
            {
              staticClass: "trailer-modal__close splittable",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onCloseClick.apply(null, arguments)
                },
              },
            },
            [_vm._v("Close")]
          ),
          _vm._v(" "),
          _vm.isVisible
            ? _c("iframe", {
                attrs: {
                  src: "https://www.youtube.com/embed/inL630qzCnI?controls=0&modestbranding=1",
                  frameborder: "0",
                  allow:
                    "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                  allowfullscreen: "",
                },
              })
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }