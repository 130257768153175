<template>
  <div class="trailer-modal" :class="{ 'is-visible':isVisible }">
    <div class="trailer-modal__background" @click.prevent="onCloseClick"></div>
    <div class="trailer-modal__content">
      <div class="trailer-modal__video">
        <a class="trailer-modal__close splittable" @click.prevent="onCloseClick">Close</a>
        <iframe
          src="https://www.youtube.com/embed/inL630qzCnI?controls=0&modestbranding=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen v-if="isVisible"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Trailer',

    props: {
      isVisible:Boolean
    },

    methods: {
      onCloseClick: function() {
        this.close();
      },
      
      close: function() {
        this.$emit('closeTrailer');
      }
    }
  };
</script>
