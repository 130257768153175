import Vue from 'vue';
import App from './App.vue';

import './styles/index.scss';

// import "aos/dist/aos.css";

// import AOS from 'aos';

new Vue({
  render: h => h(App),
}).$mount('#app');


// function setPerspectives() {
//   const scrollLocation = window.scrollY;
//   const vanishingPoint = scrollLocation + window.innerHeight / 3;
//   const pillars = document.querySelectorAll('.pillar');


//   for (let pillar of pillars) {
//     var yPerspective = (vanishingPoint - pillar.getBoundingClientRect().top - scrollLocation);
//     var XPerspective = pillar.classList.contains('pillar--left') ? 30 : -10;
//     pillar.style.transform = 'translateY(' + -yPerspective / 2.8 + 'px)';
//     pillar.querySelector('.walls').style['perspective-origin'] = XPerspective + 'vw ' + yPerspective + 'px';
//   };
// }

// function shakeScreen() {
//   for (let topElement of document.body.children) {
//     topElement.classList.add('shake');
//   }

//   setTimeout(() => {
//     for (let topElement of document.body.children) {
//       topElement.classList.remove('shake');
//     }
//   }, 2000);
// }

// window.addEventListener('scroll', () => {
//   setPerspectives();
// });

// window.onload = function() {
//   setPerspectives();
//   AOS.init();
// };

// // setTimeout(() => {
// //   shakeScreen();
// // }, 2000);