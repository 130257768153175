<template>
  <div class="object" :class="'object--' + type" :style="{ left:left+'vw', top:top +'vh' }"></div>
</template>

<script>
  export default {
    name: 'Object',
    props: {
      left: Number,
      top: Number,
      type: String,
      modifier: String
    }
  }
</script>
