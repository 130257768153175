<template>
  <div class="credit is-shaking" :class="['credit--' + name, { 'is-visible':isVisible }]">
  </div>
</template>

<script>
  export default {
    name: 'Credit',
    props: {
      name:String,
      scrollStart:Number,
      scrollEnd:Number,
      scrollLocation: Number,
    },
    data: function() {
      return {
        googleAnalyticsSent: false
      };
    },
    computed: {
      isVisible: function() {
        if (this.inScrollArea()) {
          if (!this.googleAnalyticsSent) {
            ga('send', 'event', 'Credit', 'Display', this.name);
            this.googleAnalyticsSent = true;
          }
          return true;
        }
        return false;
      }
    },
    mounted: function() {
      this.creditsShown = false;
      this.doneShowingCredits = false;
    },
    methods: {
      inScrollArea() {
        return this.scrollLocation >= this.scrollStart && this.scrollLocation <= this.scrollEnd;
      }
    }
  };
</script>