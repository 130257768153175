var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "div",
        { staticClass: "viewport", attrs: { id: "viewport" } },
        [
          _c("div", { staticClass: "texture" }),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLoading,
                    expression: "!isLoading",
                  },
                ],
                staticClass: "scroll-container",
                attrs: { id: "scroll-container" },
              },
              [
                _c("transition", { attrs: { name: "scroll-in" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isLoading,
                          expression: "!isLoading",
                        },
                      ],
                      staticClass: "floor",
                      style: {
                        left: _vm.scrollerX / 2.0 + "px",
                        width: _vm.scrollWidth + "vw",
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "trailer-button",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onTrailerClick.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "small splittable" }, [
                            _vm._v("Watch"),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "splittable" }, [
                            _vm._v("Trailer"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "buy-now-button splittable",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onBuyClick.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Buy now")]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "logo" }, [_vm._v("APE OUT")]),
                      _vm._v(" "),
                      _vm._l(_vm.objects, function (object, index) {
                        return _c("Object", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                (!object.appears_after &&
                                  !object.disappears_after) ||
                                (object.disappears_after &&
                                  object.disappears_after >
                                    _vm.furthestScrolledInVW) ||
                                (object.appears_after &&
                                  object.appears_after <
                                    _vm.furthestScrolledInVW),
                              expression:
                                "(!object.appears_after && !object.disappears_after) || (object.disappears_after && object.disappears_after > furthestScrolledInVW) || (object.appears_after && object.appears_after < furthestScrolledInVW)",
                            },
                          ],
                          key: index,
                          attrs: {
                            type: object.type,
                            top: object.top,
                            left: object.left * _vm.xMultiplier + _vm.xAdjuster,
                          },
                        })
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "buy-now",
                          style: {
                            left: 494 * _vm.xMultiplier + _vm.xAdjuster + "vw",
                          },
                        },
                        [
                          _c("div", { staticClass: "buy-now__text" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "buy-now__list" }, [
                            _c("a", {
                              staticClass:
                                "buy-now__icon buy-now__icon--switch",
                              attrs: {
                                target: "_blank",
                                href: "https://www.nintendo.com/games/detail/ape-out-switch",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onStoreClick("Nintendo")
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass: "buy-now__icon buy-now__icon--steam",
                              attrs: {
                                target: "_blank",
                                href: "https://store.steampowered.com/app/447150/Ape_Out/",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onStoreClick("Steam")
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass: "buy-now__icon buy-now__icon--gog",
                              attrs: {
                                target: "_blank",
                                href: "https://www.gog.com/game/ape_out?utm_medium=link&utm_source=Devolver&utm_campaign=partner_links",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onStoreClick("GOG")
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "buy-now__icon buy-now__icon--humble",
                              attrs: {
                                target: "_blank",
                                href: "https://www.humblebundle.com/store/ape-out",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onStoreClick("Humble Bundle")
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass: "buy-now__icon buy-now__icon--itch",
                              attrs: {
                                target: "_blank",
                                href: "https://devolverdigital.itch.io/ape-out",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onStoreClick("Itch.io")
                                },
                              },
                            }),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("transition", { attrs: { name: "scroll-in" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isLoading,
                          expression: "!isLoading",
                        },
                      ],
                      staticClass: "walls",
                      style: { width: _vm.scrollWidth + "vw" },
                    },
                    _vm._l(_vm.walls, function (wall, index) {
                      return _vm.scrollInVW + 200 >
                        wall.left * _vm.xMultiplier + _vm.xAdjuster &&
                        _vm.scrollInVW - 100 <
                          wall.left * _vm.xMultiplier +
                            _vm.xAdjuster +
                            wall.width
                        ? _c("Wall", {
                            key: index,
                            attrs: {
                              type: wall.type,
                              top: wall.top,
                              left: wall.left * _vm.xMultiplier + _vm.xAdjuster,
                              width: wall.width * _vm.xMultiplier,
                              height: wall.height,
                              zHeight: wall.z_height,
                              portraitHeight: wall.portrait_height,
                              maintainRatio: wall.maintain_ratio,
                              displayMode: _vm.displayMode,
                              yPerspective: wall.y_perspective,
                              sides: wall.sides,
                              vanishingPoint: _vm.vanishingPoint,
                            },
                          })
                        : _vm._e()
                    }),
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("Trailer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading",
          },
        ],
        attrs: { isVisible: _vm.isTrailerVisible },
        on: { closeTrailer: _vm.onTrailerClose },
      }),
      _vm._v(" "),
      _c("Credit", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading",
          },
        ],
        attrs: {
          name: "cuzzillo",
          scrollStart: 280 * _vm.xMultiplier + _vm.xAdjuster,
          scrollEnd: 345 * _vm.xMultiplier + _vm.xAdjuster,
          scrollLocation: _vm.scrollInVW,
        },
      }),
      _vm._v(" "),
      _c("Credit", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading",
          },
        ],
        attrs: {
          name: "boch",
          scrollStart: 380 * _vm.xMultiplier + _vm.xAdjuster,
          scrollEnd: 445 * _vm.xMultiplier + _vm.xAdjuster,
          scrollLocation: _vm.scrollInVW,
        },
      }),
      _vm._v(" "),
      _c("Credit", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading",
          },
        ],
        attrs: {
          name: "foddy",
          scrollStart: 480 * _vm.xMultiplier + _vm.xAdjuster,
          scrollEnd: 545 * _vm.xMultiplier + _vm.xAdjuster,
          scrollLocation: _vm.scrollInVW,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }